import React, { Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

import AntdSpin from 'antd/lib/spin';

import ErrorBoundary from '../components/error-boundary';
import ProtectedRoute from 'auth/protected-route';
import SignUpView from 'modules/signup';
import { AuthProvider } from 'providers/auth-provider';
import { FeatureFlagProvider } from 'providers/feature-flag-provider';

import Page from './page';
import s from './s.module.scss';
import UserProfile from './user-profile';

const HomeView = lazy(() => import('../modules/home'));
const LoginView = lazy(() => import('../modules/login'));
const EmailVerificationView = lazy(() => import('../modules/email-verification'));
const DashBoardView = lazy(() => import('../modules/dashboard'));
const StakingView = lazy(() => import('../modules/staking'));
const AirdropView = lazy(() => import('../modules/airdrop'));
const PlugUtilityView = lazy(() => import('../modules/plug-utility'));
const PlugRedemptionView = lazy(() => import('../modules/plug-redemption'));
const DexView = lazy(() => import('../modules/dex'));
const AddressBookView = lazy(() => import('../modules/address-book'));
const SwapView = lazy(() => import('../modules/swap'));
const GovernanceView = lazy(() => import('../modules/governance'));
const EkycSuccessView = lazy(
  () => import('../modules/verification/views/verify-result/EkycSuccess')
);
const EkycFailureView = lazy(
  () => import('../modules/verification/views/verify-result/EkycFailure')
);

const LayoutView: React.FC = () => {
  return (
    <ErrorBoundary>
      <AuthProvider>
        <FeatureFlagProvider>
          <Page>
            <main className={s.main}>
              <Suspense
                fallback={
                  <div className={s.loadingModule}>
                    <AntdSpin className="pv-24 ph-64" />
                  </div>
                }
              >
                <Switch>
                  <Route path="/" exact component={HomeView} />
                  <ProtectedRoute path="/user/:module" component={UserProfile} />
                  <Route path="/login" component={LoginView} />
                  <Route path="/signup" exact component={SignUpView} />
                  <Route path="/email-verification" exact component={EmailVerificationView} />
                  <Route path="/ekyc/success" exact component={EkycSuccessView} />
                  <Route path="/ekyc/failure" exact component={EkycFailureView} />
                  <ProtectedRoute path="/dashboard" exact component={DashBoardView} />
                  <ProtectedRoute path="/staking" component={StakingView} />
                  <ProtectedRoute path="/airdrop" component={AirdropView} />
                  <ProtectedRoute path="/plug-utility" component={PlugUtilityView} />
                  <ProtectedRoute path="/plug-catalyst-redemption" component={PlugRedemptionView} />
                  <ProtectedRoute path="/dex" component={DexView} />
                  <ProtectedRoute path="/address-book" component={AddressBookView} />
                  <ProtectedRoute path="/swap" component={SwapView} />
                  <ProtectedRoute path="/governance" component={GovernanceView} />
                </Switch>
              </Suspense>
            </main>
          </Page>
        </FeatureFlagProvider>
      </AuthProvider>
    </ErrorBoundary>
  );
};

export default LayoutView;
