import { FC, ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import config from 'config';
import dayjs from 'dayjs';
import jwt_decode from 'jwt-decode';

import * as Sentry from '@sentry/react';
import AES from 'crypto-js/aes';
import enc from 'crypto-js/enc-utf8';
import { basicLogin, otpLogin, startMfaFlow, userForgotPassword } from 'utils/fetchRequest';

interface Auth0User {
  sub: string;
  exp: string;
}

interface AuthContextProps {
  isLoading: boolean;
  errorMessage: string | null | undefined;
  isAuthenticated: boolean;
  accessToken: string | null | undefined;
  user: Auth0User | null | undefined;
  mfaEnrolInfo: MfaEnrol | null | undefined;
  mfaToken: string | null | undefined;
  loginUser: (email: string, password: string) => void;
  loginWithOtp: (otp: string) => void;
  logout: () => void;
  clearErrorMessage: () => void;
  forgotPassword: (email: string) => void;
}

interface MfaEnrol {
  barcodeUri: string;
  secret: string;
  recoveryCode: string;
}

const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

const useAuthContext = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuthContext must be used within AuthProvider');
  }
  return context;
};

export const AuthProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [accessToken, setAccessToken] = useState<string | null>();
  const [user, setUser] = useState<Auth0User | null>();
  const [isLoading, setIsLoading] = useState(true);
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [errorMessage, setErrorMessage] = useState<string | null>();
  const [mfaToken, setMfaToken] = useState<string | null>();
  const [mfaEnrolInfo, setMfaEnrolInfo] = useState<MfaEnrol | null>();

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      const persistToken = sessionStorage.getItem('access_token');
      if (persistToken) {
        try {
          const decryptedTokenBytes = AES.decrypt(persistToken, config.accessTokenPassword);
          const decryptedToken = decryptedTokenBytes.toString(enc);
          if (decryptedToken) {
            const user: Auth0User = jwt_decode(decryptedToken);
            if (user.exp && dayjs(Number(user.exp) * 1000).isBefore(dayjs())) {
              resetUserLoginInfo();
            } else {
              setAccessToken(decryptedToken);
              setUser(user);
              setIsAuthenticated(true);
            }
          }
        } catch (error) {
          Sentry.captureException(error);
          resetUserLoginInfo();
        }
      } else {
        setIsAuthenticated(false);
      }
      setIsLoading(false);
    })();
  }, []);

  const resetUserLoginInfo = () => {
    setAccessToken(null);
    setUser(null);
    setIsAuthenticated(false);
  };

  const loginSuccess = async (accessToken: string) => {
    setIsAuthenticated(true);
    setAccessToken(accessToken);
    setUser(jwt_decode(accessToken));
    try {
      const encryptedToken = AES.encrypt(accessToken, config.accessTokenPassword);
      sessionStorage.setItem('access_token', encryptedToken.toString());
    } catch (error) {
      console.error(error);
    }
    if (mfaEnrolInfo?.recoveryCode) {
      history.push('/login/mfa-recovery-code');
    } else {
      history.push('/user/verification/overview');
    }
  };

  const loginUser = async (email: string, password: string) => {
    try {
      setIsLoading(true);
      const { response, error } = await basicLogin(email, password);
      if (
        error ||
        (response?.data?.error &&
          response?.data?.error?.toUpperCase() !== 'mfa_required'?.toUpperCase())
      ) {
        setErrorMessage(t('auth_login_failed'));
        return;
      }

      if (response.success) {
        loginSuccess(response.data.accessToken);
      } else {
        if (response.data.error?.toUpperCase() === 'mfa_required'?.toUpperCase()) {
          setMfaToken(response.data.mfaToken);
          await getAuthenticators(response.data.mfaToken, email);
        }
      }
    } catch (error) {
      Sentry.captureException(error);
      setErrorMessage(error as string);
    } finally {
      setIsLoading(false);
    }
  };

  const loginWithOtp = async (otpCode: string) => {
    try {
      setIsLoading(true);
      if (mfaToken) {
        const { response, error } = await otpLogin(otpCode, mfaToken);

        if (error || response?.data?.error) {
          setErrorMessage(t('auth_login_otp_code_error'));
          return;
        }

        if (response.success) {
          loginSuccess(response.data.accessToken);
        }
      } else {
        setErrorMessage(t('auth_login_missing_mfa_error'));
      }
    } catch (error) {
      setErrorMessage(error as string);
      Sentry.captureException(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getAuthenticators = async (mfaToken: string, email: string) => {
    try {
      const { response, error } = await startMfaFlow(mfaToken, email);
      if (response.data.error) {
        if (response.data.error?.toUpperCase() === 'enrolment_required'?.toUpperCase()) {
          setMfaEnrolInfo({
            barcodeUri: response.data.barcodeUri,
            secret: response.data.secret,
            recoveryCode: response.data.recoveryCodes?.[0],
          });

          history.push(`/login/mfa-enrollment`);
        } else {
          history.push(`/login/otp`);
        }
      }
    } catch (error) {
      setErrorMessage(error as string);
      Sentry.captureException(error);
    }
  };

  const logout = () => {
    setAccessToken(null);
    setIsAuthenticated(false);
    setMfaEnrolInfo(null);
    setUser(null);
    setErrorMessage(null);
    setMfaToken(null);
    sessionStorage.removeItem('access_token');
    history.push('/login');
  };

  const forgotPassword = (email: string) => {
    userForgotPassword(email);
  };

  const clearErrorMessage = () => {
    setErrorMessage(null);
  };

  return (
    <AuthContext.Provider
      value={{
        isLoading,
        errorMessage,
        isAuthenticated,
        mfaToken,
        accessToken,
        user,
        mfaEnrolInfo,
        loginUser,
        loginWithOtp,
        logout,
        clearErrorMessage,
        forgotPassword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default useAuthContext;
